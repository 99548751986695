import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { IAirtag } from "../api/airtags";
import { ILocationUpdate } from "../api/locationUpdates";
import { getDateString, getTimeString, isSameDay } from "../utils/time";

interface Props {
  airtags: IAirtag[];
  onAirtagChanged?: (at: IAirtag) => void;
  displayOne?: boolean;
  defaultSelectedAirtag?: IAirtag;
  openedLocation?: ILocationUpdate;
}
const AirtagsMap: React.FC<Props> = ({
  airtags,
  displayOne,
  openedLocation,
  defaultSelectedAirtag,
}) => {
  const [openedLocationWindow, setOpenedLocationWindow] = useState<ILocationUpdate | null>();

  useEffect(() => {
    if (!openedLocation) return;
    setOpenedLocationWindow(openedLocation);
    // map?.setZoom(9);

    map?.panTo({ lat: openedLocation.latitude, lng: openedLocation.longitude });
    map?.setZoom(16);
  }, [openedLocation]);

  useEffect(() => {
    if (defaultSelectedAirtag) setSelectedAirtag(defaultSelectedAirtag);
  }, [defaultSelectedAirtag]);
  const [map, setMap] = useState<google.maps.Map>();

  const lastLocUpdate = (at: IAirtag) => at.locationUpdates?.[0];

  const [selectedAirtag, setSelectedAirtag] = useState<IAirtag | null>(
    defaultSelectedAirtag || null
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDgclrR8QqACLDYcgLjsLd1RIZV9-V8Bpc",
  });

  const onUnmount = React.useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    let count = 0;

    airtags.forEach((at) => {
      at.locationUpdates?.forEach((l) => {
        bounds.extend({ lat: l.latitude, lng: l.longitude });
        count++;
      });
    });

    if (count == 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(17);
    } else map.fitBounds(bounds);

    setMap(map);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%", position: "absolute", left: 0, top: 0 }}
      // center={center}
      zoom={18}
      onLoad={onLoad}
      //onUnmount={onUnmount}
    >
      <Polyline
        path={selectedAirtag?.locationUpdates?.map((l) => ({
          lat: l.latitude,
          lng: l.longitude,
        }))}
        options={{
          strokeColor: "rgb(8 145 178)",
          strokeOpacity: 0.8,
          strokeWeight: 3,
          clickable: false,
          draggable: false,
          editable: false,
          visible: true,

          zIndex: 1,
        }}
      />

      {selectedAirtag?.locationUpdates?.map((l, idx) => (
        <Marker
          key={idx}
          position={{ lat: l.latitude, lng: l.longitude }}
          onClick={() => {
            setOpenedLocationWindow(l);
          }}
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: "white",
            fillOpacity: 10,
            scale: 4.5,
            strokeColor: "rgb(251 146 60)",
            strokeWeight: 3,
          }}
        />
      ))}

      {openedLocationWindow && (
        <InfoWindow
          onCloseClick={() => setOpenedLocationWindow(null)}
          options={{
            pixelOffset:
              selectedAirtag && openedLocationWindow._id == lastLocUpdate(selectedAirtag)?._id
                ? new google.maps.Size(0, -42)
                : new google.maps.Size(0, 0),
          }}
          position={{
            lat: openedLocationWindow!.latitude,
            lng: openedLocationWindow!.longitude,
          }}
        >
          <div className="max-w-[15rem]">
            {!displayOne && (
              <h3 className="text-base font-bold text-center tracking-wide">AIRTAG - RMA-113</h3>
            )}
            <div className="rounded flex items-center text-xs tracking-wide text-gray-700">
              {/* <div className="w-100 h-[0.05em]  flex-grow  bg-gray-300"></div> */}
              <p className="mr-1">
                <i className="fas fa-calendar" /> {getDateString(openedLocationWindow.start)}{" "}
                {getTimeString(openedLocationWindow.start)} -{" "}
                {!isSameDay(openedLocationWindow.start, openedLocationWindow.end) &&
                  getDateString(openedLocationWindow.end) + " "}
                {getTimeString(openedLocationWindow.end)}
              </p>
              <div className="w-100 h-[0.05em]  flex-grow   bg-gray-300"></div>
            </div>

            <div className="w-full border-t border-gray-200 h-1"></div>
            <div className="text-sm font-bold text-blue-500">
              {openedLocationWindow.address.street || openedLocationWindow.address.pointOfIntrest}
            </div>
            <div className="text-xs">
              {openedLocationWindow!.address.locality &&
                openedLocationWindow!.address.locality + ", "}
              {openedLocationWindow!.address.state && openedLocationWindow!.address.state + ", "}
              {openedLocationWindow!.address.country &&
                openedLocationWindow!.address.country.toUpperCase()}
            </div>

            <div className="flex justify-between text-xs text-gray-500 mt-1 hidden1">
              <a
                href={`https://maps.google.com/?q=${openedLocationWindow.latitude},${openedLocationWindow.longitude}`}
                className="underline"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fas fa-map-marked" /> {openedLocationWindow.latitude.toFixed(5)}
                {", "}
                {openedLocationWindow.longitude.toFixed(5)}
              </a>{" "}
              <p className="inline ml-1 text-orange-600">
                <i className="fas fa-circle-notch" /> {openedLocationWindow.pings.toLocaleString()}
              </p>
              {/* <div className="">
                <i className="fas fa-location" /> {openedLocationWindow.accuracy.toFixed(0)}m
              </div> */}
            </div>
          </div>
        </InfoWindow>
      )}

      {openedLocationWindow && (
        <Circle
          radius={openedLocationWindow!.accuracy}
          center={{
            lat: openedLocationWindow!.latitude,
            lng: openedLocationWindow!.longitude,
          }}
          options={{
            strokeColor: "rgb(29 78 216)",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: "rgb(29 78 216)",
            fillOpacity: 0.2,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
          }}
        />
      )}

      {selectedAirtag &&
        openedLocationWindow?._id != lastLocUpdate(selectedAirtag)?._id &&
        lastLocUpdate(selectedAirtag) && (
          <Circle
            radius={lastLocUpdate(selectedAirtag)!.accuracy}
            center={{
              lat: lastLocUpdate(selectedAirtag)!.latitude,
              lng: lastLocUpdate(selectedAirtag)!.longitude,
            }}
            options={{
              strokeColor: "rgb(29 78 216)",
              strokeOpacity: 0.6,
              strokeWeight: 2,
              fillColor: "rgb(29 78 216)",
              fillOpacity: 0.2,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
            }}
          />
        )}

      {airtags.map(
        (at, idx) =>
          lastLocUpdate(at) && (
            <Marker
              key={idx}
              onClick={() => {
                if (selectedAirtag && selectedAirtag.shortId == at.shortId) {
                  setOpenedLocationWindow(lastLocUpdate(at));
                }
                setSelectedAirtag(at);
                if ((map?.getZoom() || 0) > 17) return;
                map?.panTo({ lat: lastLocUpdate(at)!.latitude, lng: lastLocUpdate(at)!.longitude });
                map?.setZoom(17);
              }}
              position={{
                lat: lastLocUpdate(at)!.latitude,
                lng: lastLocUpdate(at)!.longitude,
              }}
              zIndex={999}
              label={{
                text:
                  at.shortId +
                  (displayOne
                    ? ""
                    : ` | ${at.currentJourney ? "To" : "In"} ${
                        at.currentJourney
                          ? at.currentJourney.destinationCountry //.toUpperCase()
                          : at.latestLocationUpdate?.address.country
                      }`),
                className: "lbl-imp mb-[66px] bg-white shadow-xl p-0.5 rounded font-smibold",
                fontSize: "12px",
                fontWeight: "bold",
              }}
              icon={{
                url: `/airtag-${selectedAirtag?.serial == at.serial ? "blue" : "gray"}-marker.png`,
                anchor: new google.maps.Point(21.5, 21.5),
              }}
            />
          )
      )}
    </GoogleMap>
  ) : null;
};

export default AirtagsMap;
