export const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export function timeSinceShort(timeStamp: Date) {
  var now = new Date();

  var secondsPast = Math.round(now.getTime() - timeStamp.getTime()) / 1000;

  if (secondsPast < 60) {
    return "just now";
    // return secondsPast + "s";
  }
  if (secondsPast < 3600) {
    return (secondsPast / 60).toFixed(0) + "m";
  }
  if (secondsPast <= 86400) {
    return (secondsPast / 3600).toFixed(0) + "h";
  }
  if (secondsPast <= 2628000) {
    return (secondsPast / 86400).toFixed(0) + "d";
  }
  if (secondsPast <= 31536000) {
  }
  if (secondsPast > 31536000) {
    return (secondsPast / 31536000).toFixed(0) + "y";
  }
}

export const getDateString = (date: Date, fullYear = false) => {
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = fullYear ? date.getFullYear() : date.getFullYear().toString().substring(2);
  return day + "/" + month + "/" + year;
};

export const getTimeString = (date: Date, withSec = false) => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}${withSec ? ":" + seconds : ""}`;
};

export const isSameDay = (dt1: Date, dt2: Date) =>
  dt1.getFullYear() === dt2.getFullYear() &&
  dt1.getMonth() === dt2.getMonth() &&
  dt1.getDate() === dt2.getDate();
