import axios, { Method } from "axios";
import { isoDateFormat } from "../utils/time";
const client = axios.create({ baseURL: "/api" });

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object") return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === "object") handleDates(value);
  }
}

client.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

export default async function MakeApiRequest(
  method: Method,
  endPoint: string,
  body?: any,
  params?: any
) {
  return await client({
    url: endPoint,
    method,
    params,
    data: body,
    withCredentials: true,
  });
}
