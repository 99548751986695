import React from "react";
import AirtagOverview from "../components/AirtagOverview";

const HomePage: React.FC = () => {
  return (
    <div className="container px-4 px-2 py-3 w-full mx-auto ">
      <h1 className="text-4xl font-semibold mb-20">Airtags Overview</h1>

      {/* <AirtagOverview>ff</AirtagOverview> */}
    </div>
  );
};
export default HomePage;
