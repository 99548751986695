import { IJourney } from "./journey";
import { ILocationUpdate } from "./locationUpdates";
import MakeApiRequest from "./makeRequest";

export interface IAirtag extends Document {
  title: string;
  description?: string;
  serial?: string;
  shortId?: string;
  latestLocationUpdate: ILocationUpdate | null;
  currentJourney: IJourney | null;
  locationUpdates?: ILocationUpdate[];
  journeys?: IJourney[];
}

export default class AirtagsAPI {
  static async getAll(): Promise<IAirtag[]> {
    const res = await MakeApiRequest("GET", "/airtags");
    const data: IAirtag[] = res.data;
    return data;
  }

  static async getOne(id: string): Promise<IAirtag> {
    const res = await MakeApiRequest("GET", "/airtags/" + id);
    const data: IAirtag = res.data;
    return data;
  }
}
