import React from "react";
import { ILocationUpdate } from "../../api/locationUpdates";
import { battToFontawesomeIcon } from "../../utils";
import { getDateString, getTimeString, isSameDay } from "../../utils/time";

interface Props {
  onClick?: (locUpdate: ILocationUpdate) => void;
  locationUpdate: ILocationUpdate;
  className?: string;
}

const LocationUpdateCard: React.FC<Props> = ({ locationUpdate, onClick, className }) => {
  const { battery, latitude, longitude, address, start, end, pings, accuracy, moved } =
    locationUpdate;
  return (
    <div
      onClick={() => onClick?.(locationUpdate)}
      className={
        "rounded bg-white cursor-pointer hover:bg-slate-50 foucus p-[0.6em] shadow-sm! mb-2 " +
        className
      }
    >
      <div className="flex justify-between border-b">
        <div className="text-sm text-gray-500 font-sans">
          <i className="fas fa-clock text-blue-600" /> {getTimeString(start)}
          {start.getTime() != end.getTime() && " - "}
          {!isSameDay(start, end) && getDateString(end) + " "}
          {start.getTime() != end.getTime() && getTimeString(end)}
        </div>
        <div className="text-sm">
          <p className="inline mr-1 text-orange-600">
            <i className="fas fa-circle-notch" /> {pings.toLocaleString()}
          </p>

          <p className="inline text-blue-400 font-semibold">|</p>

          <p className="inline ml-1 text-emerald-500">
            <i className={"fas " + battToFontawesomeIcon(battery)} />
          </p>
        </div>
      </div>
      <div className="devider"></div>
      <h6 className="font-bold text-l text-blue-400">
        {/* <i className="fas fa-location1" /> */}
        {address.pointOfIntrest || address.street}
      </h6>

      <p className="text-xs text-gray-700 uppercase tracking-wide font-semibold1  flex justify-between">
        <div className="">
          <i className="fas fa-city" /> {address.locality && address.locality + ", "}
          {address.state}
        </div>

        <div className="">
          <i className="fas fa-globe-europe" /> {address.country}
          {/* {" | "} */}{" "}
          <img
            className="inline w-5 border-gray-600 border-[0px] z-0	shadow-2xl filter drop-shadow-xl	"
            src={`https://flagcdn.com/w20/${address.countryCode?.toLocaleLowerCase()}.png`}
            width="auto"
            alt=""
          />
        </div>
      </p>

      <p className="text-xxs text-gray-500 uppercase tracking-wide mt-1 mx-s-5 flex justify-between ">
        <div className="">
          <i className="fas fa-map-marked" />{" "}
          <a
            href={`https://maps.google.com/?q=${latitude},${longitude}`}
            className="underline"
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => console.log("clied")}
          >
            {latitude.toFixed(5)}, {longitude.toFixed(5)}
          </a>{" "}
          | <i className="fas fa-location" /> {accuracy.toFixed(0)}m acr
        </div>

        {moved && (
          <div className="">
            <i className="fas fa-ruler-horizontal" />{" "}
            {moved < 1 ? moved.toFixed(3) : Number(moved.toFixed(0)).toLocaleString()} km
          </div>
        )}
      </p>
    </div>
  );
};

export default LocationUpdateCard;
