import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import AirtagsAPI, { IAirtag } from "../api/airtags";
import { IJourney } from "../api/journey";
import { ILocationUpdate } from "../api/locationUpdates";
import AirtagsMap from "../components/AirtagsMap";
import LocationUpdateCard from "../components/locationUpdates/Card";
import { isMobile } from "../utils";
import { getDateString, isSameDay, timeSinceShort } from "../utils/time";

const AirtagPage: React.FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadedAirtag, setLoadedAirtag] = useState<IAirtag | null>(null);
  const [selectedLocationUpdate, setSelectedLocationUpdate] = useState<ILocationUpdate>();
  const [selectedJourney, setSelectedJourney] = useState<IJourney>();

  const findJourneyFromId = (journeys: IJourney[], id: string) => {
    for (const j of journeys) {
      if (j._id == id) return j;
    }
  };

  const filtredLocationUpdates = useMemo(() => {
    if (!loadedAirtag?.locationUpdates) return [];

    if (!selectedJourney) return loadedAirtag.locationUpdates;

    return loadedAirtag.locationUpdates?.filter(({ start, end }) => {
      return selectedJourney.start < start && (selectedJourney.end || new Date()) > end;
    });
    //.slice(1, 2);
  }, [selectedJourney, loadedAirtag]);

  const changeAirtagLocationUpdates = (at: IAirtag, lcs: ILocationUpdate[]) => {
    const newAt: IAirtag = { ...at, locationUpdates: lcs };
    return newAt;
  };

  const [firstSelectedJourney, setFirstSelectedJourney] = useState(false);
  useEffect(() => {
    const loadAirtag = async () => {
      try {
        const airtag = await AirtagsAPI.getOne(params.id!);
        setFirstSelectedJourney((value) => {
          if (value) return true;

          setSelectedJourney(
            findJourneyFromId(airtag.journeys || [], searchParams.get("journey") || "") ||
              airtag.journeys![-1]
          );
          return true;
        });
        setLoadedAirtag(airtag);
      } catch (error) {}

      setTimeout(() => loadAirtag(), 60 * 1000);
    };
    loadAirtag();
  }, []);

  return (
    <div className="mt-[-0.75rem]">
      <div className=" bg-gradient-to-r from-sky-800 to-sky-400  width-100 sticky top-0 z-20 shadow-lg">
        <div className=" container w-full mx-auto p-2 pb-0 flex justify-between flex-wrap gap-y-">
          <div className="bg-white width-auto inline-block p-1 rounded-full text-xs shadow-lg h-fit">
            {" "}
            <i className="fas fa-clock"></i>
            {" Seen "}
            {loadedAirtag?.latestLocationUpdate?.end &&
              timeSinceShort(loadedAirtag?.latestLocationUpdate.end)}
            {loadedAirtag?.latestLocationUpdate?.end &&
              timeSinceShort(loadedAirtag?.latestLocationUpdate.end) != "just now" &&
              " ago"}
            {/* {" | "} */}
            {/* <i className="fas fa-battery-full"></i> {loadedAirtag?.latestLocationUpdate?.battery}% */}
          </div>
          <h1 className="text-center text-base font-regular text-slate-300 flex items-center tracking-wide">
            <i
              style={{
                fontSize: ".7em",
                display: "none",
                boxShadow: "0 0 0px white",
                padding: "0.27em 0.4em",
                border: "solid #E2E7F0 .15em",
              }}
              className="fab fa-apple rounded-full mr-[.27em]"
            />
            ID:{" "}
            <span className="font-semibold ml-1 text-xl text-white tracking-normal">
              {loadedAirtag?.shortId}
            </span>
          </h1>

          <div className="bg-white width-auto inline-block p-1 rounded-full text-xs h-fit">
            <i className="fas fa-location-arrow"></i>{" "}
            {loadedAirtag?.latestLocationUpdate?.address.locality &&
              loadedAirtag?.latestLocationUpdate?.address.locality + ", "}
            {loadedAirtag?.latestLocationUpdate?.address.country}
          </div>
        </div>
        <div className="p-2 pt-0 text-center text-white flex  justify-between container w-full mx-auto">
          <div className="text-left">
            <h2 className="text-xl font-bold">
              {loadedAirtag?.currentJourney
                ? `Traveling to ${loadedAirtag?.currentJourney.destinationCountry}`
                : `In ${loadedAirtag?.latestLocationUpdate?.address?.country}`}
            </h2>
            <p className="text-gray-300 ">{loadedAirtag?.description}</p>
          </div>
          <div className="flex ">
            <div className="">
              <img
                className="inline"
                src={`https://flagcdn.com/w20/${loadedAirtag?.currentJourney?.originCountryCode?.toLocaleLowerCase()}.png`}
                width="auto"
                alt=""
              />
              <p className="mt-[-4px]">
                {loadedAirtag?.currentJourney?.originCountryCode || "N/A"}
              </p>
            </div>
            <i className="fas fa-plane text-2xl ml-2 mr-2"></i>
            <div className="">
              <img
                className="inline"
                src={`https://flagcdn.com/w20/${loadedAirtag?.currentJourney?.destinationCountryCode?.toLocaleLowerCase()}.png`}
                width="auto"
                alt=""
              />
              <p className="mt-[-4px]">
                {loadedAirtag?.currentJourney?.destinationCountryCode || "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 py-3 w-full mx-auto ">
        <div className="bg-white my-1 md:w-fit w-full md:flex items-center p-4 py-3 rounded shadow-sm tracking-wider">
          <h4 className="font-sans uppercase text-gray-500">Select Travel</h4>
          <select
            value={selectedJourney?._id || ""}
            onChange={({ target }) => {
              if (!loadedAirtag) return;
              const id = target.value;
              if (!id) {
                setSearchParams({}, { replace: true });
                return setSelectedJourney(undefined);
              }
              setSelectedJourney(findJourneyFromId(loadedAirtag.journeys!, id));
              setSearchParams({ journey: id }, { replace: true });
            }}
            className="border border-solid border-gray-300 p-1 shadow-l rounded font-bold md:ml-2"
          >
            {loadedAirtag?.journeys?.map((j, idx) => (
              <option value={j._id} key={j._id}>
                {j.originCountry} {"->"} {j.destinationCountry} ({getDateString(j.start)}
                {" - "}
                {j.end ? getDateString(j.end!) : "ongoing"})
              </option>
            ))}
            <option value="">All</option>
          </select>
        </div>

        <div className="md:flex justify-between md:flex-row-reverse">
          <div className="map md:w-[65%] mxh-56  md:ml-10 md:mt-0 mt-5">
            <h1 className="text-2xl font-semibold mb-1 ">Map</h1>
            <div className="google-map  md:aspect-auto aspect-[1]  md:pt-0 pt-[100%]" style={{}}>
              {loadedAirtag && (
                <AirtagsMap
                  displayOne={true}
                  defaultSelectedAirtag={changeAirtagLocationUpdates(
                    loadedAirtag,
                    filtredLocationUpdates
                  )}
                  openedLocation={selectedLocationUpdate}
                  airtags={
                    loadedAirtag
                      ? [changeAirtagLocationUpdates(loadedAirtag, filtredLocationUpdates)]
                      : []
                  }
                />
              )}
            </div>
          </div>
          {/* {68.5} */}
          <div className="history md:w-[35%] max11-w-[400px] md:mt-0 mt-5 text-">
            <h1 className="text-2xl font-semibold mb-1">Location Updates History</h1>
            <div className="overflow-auto sm:h-[71vh]	[scrollbar-background:red]">
              {filtredLocationUpdates?.map((lc, idx, lcs) => (
                <>
                  {(!lcs[idx - 1] || !isSameDay(lcs[idx - 1]!.start, lc.start)) && (
                    <div
                      key={"d" + idx}
                      className="rounded flex items-center bg-bg-main bg-white! py-2 text-xl tracking-wide text-gray-700 sticky top-0 z-10"
                    >
                      {/* <div className="w-100 h-[0.05em]  flex-grow  bg-gray-300"></div> */}
                      <p className="mr-2">
                        <i className="fas fa-calendar"></i> {getDateString(lc.start)}
                      </p>
                      <div className="w-100 h-[0.05em]  flex-grow  bg-gray-300"></div>
                    </div>
                  )}
                  <LocationUpdateCard
                    key={"l" + idx}
                    locationUpdate={lc}
                    onClick={(lc) => {
                      setSelectedLocationUpdate(lc);
                      if (isMobile) {
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                      }
                    }}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirtagPage;
