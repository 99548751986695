import React, { useState } from "react";
import Navbar from "./layout/Navbar";
import AppRoutes from "./Routes";

function App() {
  return (
    <>
      <Navbar />
      <AppRoutes />
    </>
  );
}

export default App;
