export const isMobile = window.innerWidth < 500;

export const battToFontawesomeIcon = (bat: number) => {
  switch (bat) {
    case 1:
      return "fa-battery-full";

    case 2:
      return "fa-battery-three-quarters";

    case 3:
      return "fa-battery-half";

    case 4:
      return "fa-battery-quarter";

    default:
      return "fa-battery-full";
  }
};
